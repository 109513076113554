<template>
  <div id="individual-display-data">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-card-text style="height: 400px">
          <v-container class="py-0">
            <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
            <small>
              <span class="text-danger">*</span> indicates required field
            </small>
            <v-form ref="form1" v-model="valid1" lazy-validation>
              <v-row wrap>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> From Date</h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="FromDate"
                        label="From Date"
                        hint="Select a From Date"
                        persistent-hint
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="FromDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> To Date</h6>
                  </label>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="ToDate"
                        label="To Date"
                        hint="Select a To Date"
                        persistent-hint
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ToDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Plan Name</h6>
                  </label>
                  <v-text-field
                    v-model="PlanName"
                    :rules="PlanNameRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Maximum Registration
                      Count
                      <small>(0 for unlimited)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="MaxRegistrationCount"
                    :rules="MaxRegistrationCountRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Is this featured plan?
                    </h6>
                  </label>
                  <v-switch
                    v-model="Recommended"
                    :label="`${Recommended == 1 ? 'Yes' : 'No'}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Is this free plan?
                    </h6>
                  </label>
                  <v-switch
                    v-model="FreePlan"
                    :label="`${FreePlan == 1 ? 'Yes' : 'No'}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Active Status</h6>
                  </label>
                  <v-switch
                    v-model="ActiveStatus"
                    :label="`${ActiveStatus == 1 ? 'Active' : 'Inactive'}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Order By</h6>
                  </label>
                  <v-text-field
                    v-model="OrderBy"
                    :rules="OrderByRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="FreePlan == 0">
                  <label>
                    <h6><span class="text-danger">*</span> Sub Total</h6>
                  </label>
                  <v-text-field
                    @input="calculateTotalAmount"
                    v-model="SubTotal"
                    :rules="SubTotalRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="FreePlan == 0">
                  <label>
                    <h6><span class="text-danger">*</span> Tax</h6>
                  </label>
                  <v-text-field
                    @input="calculateTotalAmount"
                    v-model="Tax"
                    :rules="TaxRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="FreePlan == 0">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Total Amount
                    </h6> </label
                  ><br />
                  <h4>{{ TotalAmount }}</h4>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>
                    <h6>Content <small>(optional)</small></h6>
                  </label>
                  <ckeditor v-model="Content" :config="editorConfig"></ckeditor>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitLoadingFlag"
                    color="#8950FC"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-sheet>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="closePrompt"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import ckEditorConfig from "@/ckeditor_config.json";

export default {
  mixins: [common],
  props: {
    record: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      rowData: {},
      pageTitle: "Events Plan Edit",

      ResultFlag: false,
      LoadingFlag: false,
      SubmitLoadingFlag: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PlanName: "",
      PlanNameRules: [(v) => !!v || "Plan name is required"],

      MaxRegistrationCount: "",
      MaxRegistrationCountRules: [
        (v) => !!v || "Max registration count is required",
      ],

      Recommended: 0,
      FreePlan: 0,
      ActiveStatus: 1,

      SubTotal: "",
      SubTotalRules: [(v) => !!v || "Sub total is required"],

      Tax: "",
      TaxRules: [(v) => !!v || "Tax is required"],

      TotalAmount: "",

      OrderBy: 1,
      OrderByRules: [(v) => !!v || "Ordr By is required"],

      editorConfig: ckEditorConfig,

      Content: "",
      ContentRules: [
        // (v) => !!v || "Active status is required"
      ],
    };
  },
  computed: {},
  watch: {
    rowData: function () {
      console.log("watch rowData");
      var tr = this.rowData;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.FromDate = tr.StartDate;
        this.ToDate = tr.EndDate;
        this.PlanName = tr.PlanName;
        this.MaxRegistrationCount = tr.MaxLimit;
        this.Recommended = tr.Recommended;
        this.FreePlan = tr.FreePlan;
        this.ActiveStatus = tr.ActiveStatus;
        this.OrderBy = tr.OrderBy;
        this.SubTotal = tr.SubTotal;
        this.Tax = tr.Tax;
        this.TotalAmount = tr.TotalAmount;
        this.Content = tr.Content;
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    resetForm() {
      this.$refs.form1.reset();
      this.FromDate = "";
      this.ToDate = "";
      this.Content = "";
    },
    closePrompt() {
      console.log("closePrompt called");
      this.resetForm();
      this.$emit("hideDialog", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    calculateTotalAmount() {
      console.log("calculateTotalAmount called");
      var SubTotal = this.SubTotal;
      SubTotal = isNaN(SubTotal) ? 0 : SubTotal;
      var Tax = parseFloat(this.Tax);
      Tax = isNaN(Tax) ? 0 : Tax;
      console.log("SubTotal=" + SubTotal + ", Tax" + Tax);

      var TaxAmount = (SubTotal * Tax) / 100;
      console.log({ TaxAmount });

      var TotalAmount = parseFloat(SubTotal) + parseFloat(TaxAmount);
      console.log({ TotalAmount });
      this.TotalAmount = isNaN(TotalAmount) ? 0 : TotalAmount;
    },
    getTableRecords() {
      console.log("getTableRecords is called");
      var tr = this.record;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.rowData = {};
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/show";
        var upload = {
          UserInterface: 1,
          Event: tr.ProgramId,
          Plan: tr.PlanId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.rowData = records;
              //   thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
              // thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (n1 == 0) {
          message += "Event details not found. ";
        }
        this.toast("error", message);
        // this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");
      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });
      var tr = this.rowData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      var FromDate = this.FromDate;
      var ToDate = this.ToDate;

      if (n1 > 0 && validate1 && FromDate != "" && ToDate != "") {
        this.SubmitLoadingFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/update";
        var upload = {
          UserInterface: 1,
          Event: tr.ProgramId,
          Plan: tr.PlanId,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
          PlanName: this.PlanName,
          MaxRegistrationCount: this.MaxRegistrationCount,
          Recommended: this.Recommended,
          FreePlan: this.FreePlan,
          SubTotal: this.SubTotal,
          Tax: this.Tax,
          TotalAmount: this.TotalAmount,
          OrderBy: this.OrderBy,
          ActiveStatus: this.ActiveStatus,
          Content: this.Content,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitLoadingFlag = false;
          });
      } else {
        var message = "";
        if (n1 == 0) {
          message += "Event details not found. ";
        }
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (FromDate == "") {
          message += "From Date should not be empty. ";
        }
        if (ToDate == "") {
          message += "To Date should not be empty. ";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
