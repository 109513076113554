<template>
  <div id="individual-display-data">
    <overlay-loading-progress
      :LoadingFlag="DeleteLoadingFlag"
    ></overlay-loading-progress>
    <v-dialog
      v-model="createPlanFlag"
      v-if="createPlanFlag"
      persistent
      max-width="75%"
    >
      <jci-program-plans-create
        v-if="createPlanFlag"
        :record="record"
        @hideDialog="hideCreatePlan($event)"
      ></jci-program-plans-create>
    </v-dialog>
    <v-dialog
      v-model="editPlanFlag"
      v-if="editPlanFlag"
      persistent
      max-width="75%"
    >
      <jci-program-plans-edit
        v-if="editPlanFlag"
        :record="selectedData"
        @hideDialog="hideEditPlan($event)"
      ></jci-program-plans-edit>
    </v-dialog>
    <v-dialog v-model="dialog" scrollable max-width="300px">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <p class="text-h6" v-html="dialogContent" ></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-card-text style="height: 400px">
          <v-container class="py-0" v-if="LoadingFlag">
            <v-skeleton-loader
              v-bind="attrs"
              type="table-tbody"
            ></v-skeleton-loader>
          </v-container>
          <v-container class="py-0" v-if="!LoadingFlag">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-tooltip bottom v-if="TableOptions.CreateButtonFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click="createPlanFlag = true"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-plus-box</v-icon
                    >
                  </template>
                  <span>Add New</span>
                </v-tooltip>
                <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> -->
                <p></p>
                <v-data-table
                  v-if="TableData.length > 0"
                  class="elevation-1"
                  :single-select="false"
                  v-model="selected"
                  :headers="TableHeader"
                  :items="TableData"
                  :items-per-page="50"
                  :search="search"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50],
                  }"
                >
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.Content="{ item }">
                    <v-btn
                      class="mx-2"
                      icon
                      dark
                      small
                      color="primary"
                      @click="openDialog(item, 1)"
                    >
                      <v-icon dark> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom v-if="item.PreviewFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-file-search</v-icon
                        >
                      </template>
                      <span>Preview</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.EditFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="warning"
                          @click="editData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-file-document-edit</v-icon
                        >
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.DeleteFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="red"
                          @click="deleteData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-sheet>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="closePrompt"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import JciProgramPlansCreate from "@/view/pages/erp/jci-program/JciProgramPlansCreate.vue";
import JciProgramPlansEdit from "@/view/pages/erp/jci-program/JciProgramPlansEdit.vue";

export default {
  mixins: [common],
  components: {
    JciProgramPlansCreate,
    JciProgramPlansEdit,
  },
  props: {
    record: {
      required: true,
    },
  },
  data() {
    return {
      pageTitle: "Events Plan",

      dialog: false,
      dialogTitle: "",
      dialogContent: "",

      LoadingFlag: false,
      DeleteLoadingFlag: false,

      createPlanFlag: false,
      editPlanFlag: false,

      selected: [],
      search: "",
      selectedData: {},

      TableHeader: {},
      TableOptions: {},
      TableData: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", false);
    },
    openDialog(tr, id) {
      console.log("openDialog called");
      console.log({ id });
      console.log({ tr });
      var dialog = false;
      var dialogTitle = "";
      var dialogContent = "";
      switch (id) {
        case 1:
          dialog = true;
          dialogTitle = tr.PlanName + " content";
          dialogContent = tr.Content;
          break;

        default:
          break;
      }
      this.dialogTitle = dialogTitle;
      this.dialogContent = dialogContent;
      this.dialog = dialog;
    },
    hideCreatePlan(flag) {
      console.log("hideCreatePlan called");
      console.log({ flag });
      this.createPlanFlag = false;
      if (flag) {
        this.getTableRecords();
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.editPlanFlag = true;
      } else {
        var message = "Kindly select one plan";
        this.toast("error", message);
      }
    },
    hideEditPlan(flag) {
      console.log("hideEditPlan called");
      console.log({ flag });
      this.editPlanFlag = false;
      if (flag) {
        this.getTableRecords();
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords is called");
      var tr = this.record;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.LoadingFlag = true;

        this.TableHeader = {};
        this.TableOptions = {};
        this.TableData = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/lists";
        var upload = {
          UserInterface: 1,
          Program: tr.ProgramId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            thisIns.TableHeader = records.TableHeader;
            thisIns.TableOptions = records.TableOptions;

            if (flag == 1) {
              thisIns.TableData = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
              // thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (n1 == 0) {
          message += "Event details not found. ";
        }
        this.toast("error", message);
        // this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");

      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      var record = this.record;

      if (n1 > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/destroy";
        var upload = {
          UserInterface: 1,
          Event: record.ProgramId,
          Plan: tr.PlanId,
          PlanName: tr.PlanName,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "";
        if (n1 == 0) {
          message += "Select plan to delete. ";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    this.refreshPageData();
  },
};
</script>
